import { useSelector } from '@innedit/formidable';
import {
  CommandeModel,
  InneditContext,
  ProduitModel,
} from '@innedit/innedit-react';
import { CommandeType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Citation from '../../components/Citation';
import Content from '../../components/Content';
import Layout from '../../components/Layout';
import PanierList from '../../components/Panier/List';
import PanierResume from '../../components/Panier/Resume';
import IconLoading from '../../icons/Loading';
import { StateProps } from '../../reducers';

const PanierPage: FC<PageProps> = function ({ location: { pathname } }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [error, setError] = useState<string>();
  const [produitsAmount, setProduitsAmount] = useState<number>();
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const { user: stateUser } = useSelector((state: StateProps) => state);
  const authUser = stateUser ? stateUser.authUser : undefined;
  const user = stateUser && stateUser.item ? stateUser.item : undefined;

  const { cart, incrementCartItem, decrementCartItem, removeCartItem } =
    useContext(InneditContext);

  const cartString = JSON.stringify(cart);
  useEffect(() => {
    setProduitsAmount(CommandeModel.calculateProduitsAmount(cart));
  }, [cartString]);

  const handleCreateOrderOnClick = async () => {
    setIsInProgress(true);
    let commande;
    try {
      const data: Partial<CommandeType> = {
        language,
        contactAddress: user?.address,
        contactCity: user?.city,
        contactCountry: user?.country,
        contactEmail: user?.email || authUser?.email,
        contactFirstName: user?.firstName,
        contactLastName: user?.lastName,
        contactPhone: user?.phone || authUser?.phoneNumber,
        contactZip: user?.zip,
        deliveryAmount: 0,
        deliveryMethod: 'store',
        deliveryToBillingAddress: true,
        isDraft: true,
        produits: cart,
        userId: authUser?.uid,
      };
      commande = await CommandeModel.create(data);
    } catch ({ message }) {
      console.error(message);
      setIsInProgress(false);

      return setError("Impossible d'initialiser la création de votre commande");
    }

    if (commande) {
      await navigate(t(`pages.checkout.pathname`, { id: commande.id }));
    } else {
      setError("La commande n'a pas pu être créée");
    }

    return setIsInProgress(false);
  };

  useEffect(() => {
    cart.forEach(ligne => {
      ProduitModel.get(ligne.id)
        .then(document => {
          if (
            document.hasInventory &&
            (0 === document.qtyAvailable ||
              (document.qtyReserved > 0 &&
                document.qtyAvailable <= document.qtyReserved))
          ) {
            removeCartItem(ligne.id);
          }

          return true;
        })
        .catch(({ message }: Error) => {
          removeCartItem(ligne.id);
        });
    });
  }, [cart, removeCartItem]);

  const breadcrumbs = [
    {
      mobile: true,
      to: t('pages.inventaire.pathname'),
      value: t('pages.inventaire.title'),
    },
  ];

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pathname={pathname}
      showTitle
      title="pages.panier.title"
    >
      <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
        {(!cart || 0 === cart.length) && (
          <div className="min-w-full">
            <Citation>{t('pages.panier.empty')}</Citation>

            <div style={{ textAlign: 'center' }}>
              <Button to={t('pages.inventaire.pathname')}>
                {t('pages.panier.voir-boutique')}
              </Button>
            </div>
          </div>
        )}
        {cart && cart.length > 0 && (
          <>
            <PanierList
              handleDecrementCartItem={decrementCartItem}
              handleIncrementCartItem={incrementCartItem}
              handleRemoveItem={removeCartItem}
              items={cart}
            />
            <PanierResume panier={cart} produitsAmount={produitsAmount} />

            <div className="mt-6 flex justify-end">
              {error && <p className="text-danger-500">{error}</p>}
              {!error && (
                <Button
                  iconRight={isInProgress ? IconLoading : undefined}
                  onClick={handleCreateOrderOnClick}
                  status="primary"
                >
                  {t('pages.panier.commander')}
                </Button>
              )}
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
};

export default PanierPage;
